// Account Key for the Dropbox Pulse App in local storage
export const SPRITES_GPT_ACCOUNT_KEY = 'sprites_gpt_account';

// Route paths
export const ROUTE_PATHS = {
  HOME: '/home',
  FEEDBACK: '/feedback',
  MANAGER_FEEDBACK: '/manager_feedback',
  CAREER_FRAMEWORK: '/career_framework',
  WAITLIST: '/waitlist',
  PROFILE: '/my-profile',
};

export const ADMINS = [
  'abraham@dropbox.com',
  'alyssa@dropbox.com',
  'caseyferguson@dropbox.com',
  'gcheng@dropbox.com',
  'hasini@dropbox.com',
  'kdavis@dropbox.com',
  'rchadderdon@dropbox.com',
  'sarahtyler@dropbox.com',
  'wyattrichter@dropbox.com',
  'ysalamini@dropbox.com',
];
